import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SearchEngineOpt from '../components/SearchEngineOpt/SearchEngineOpt';
import Hero from '../components/Hero/Hero';
import TextSection from '../components/TextSection/TextSection';

export default function Terms({data}) {
  return (
    <Layout>
      <div className="page">
      <Hero
          className={data.page.data.header_css_class ? data.page.data.header_css_class.text : 'bg-secondary'}
          text={data.page.data.header_text.html}
          image={data.page.data.header_image}
        />
        <TextSection
          text={[data.page.data.terms_text.html]}
        />
      </div>
    </Layout>
  )}

  export const Head = ({ data }) => (
    <SearchEngineOpt
      title={data.page.seo.title && data.page.seo.title.text}
      description={data.page.seo.description && data.page.seo.description.text}
      image={data.page.seo.image && data.page.seo.image.url}
      keywords={''} />
  )

export const TermsQuery = graphql`
query {
  page: prismicTerms {
    uid
    seo: data {
      title {
        text
      }
      description {
        text
      }
      image {
        url
      }
    }
    data {
      header_text {
        html
      }
      terms_text {
        html
      }
    }
  }
}
`
